import type { FunctionComponent, ReactNode } from 'react';
import { useLineLoginMethods } from '~/hooks/useLineLoginMethods';

type Props = {
  children: ReactNode;
  className?: string;
  redirectPath?: string;
  onClick?: () => void;
};

export const LoginLink: FunctionComponent<Props> = (props) => {
  const { className, children, redirectPath, onClick } = props;
  const { normalLogin } = useLineLoginMethods({ redirectPath });

  const clickLink = () => {
    onClick?.();
    normalLogin();
  };

  return (
    <span onClick={clickLink} className={className}>
      {children}
    </span>
  );
};
