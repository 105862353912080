import { type FunctionComponent, type ReactNode, createContext, useContext, useState } from 'react';
import { useClientEnvContext } from './clientEnvProvider';

type ContextType = { liffId: string; isMiniApp: boolean };

export const LiffContext = createContext<ContextType>({
  liffId: '',
  isMiniApp: false
});

export const useLiffContext = (): ContextType => useContext(LiffContext);

type Props = {
  isMiniApp: boolean;
  children: ReactNode;
};

export const LiffProvider: FunctionComponent<Props> = (props) => {
  const { children, isMiniApp: initialIsMiniapp } = props;
  const { liffId: normalLiffId, miniappLiffId } = useClientEnvContext();
  const [liffId] = useState<string>(initialIsMiniapp ? miniappLiffId : normalLiffId);
  const [isMiniApp] = useState<boolean>(initialIsMiniapp);

  return <LiffContext.Provider value={{ liffId, isMiniApp }}>{children}</LiffContext.Provider>;
};
