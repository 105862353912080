import liff from '@line/liff';
import { useLocation } from '@remix-run/react';
import { useLoginQueryParameter } from './useLoginQueryParameter';
import { useClientEnvContext, useLiffContext } from '~/providers';
import { lineFriendUrl } from '~/constants/line';

type PageTransitionType = 'replace' | 'push';

type Props = {
  redirectPath?: string;
};

export const useLineLoginMethods = (props: Props) => {
  const { redirectPath: designatedRedirectPath } = props;
  const location = useLocation();
  const currentPath = `${location.pathname}${location.search}`;
  const redirectPath = designatedRedirectPath || currentPath;
  const { loginQueryParameter } = useLoginQueryParameter({ redirectPath, loginSourcePath: currentPath });
  const callbackPath = `/callback?${loginQueryParameter}`;
  const { liffId } = useLiffContext();
  const { environment } = useClientEnvContext();

  const pageTransition = ({ url, type }: { url: string; type?: PageTransitionType }) => {
    if (type === 'replace') {
      window.location.replace(url);
    } else {
      window.location.href = url;
    }
  };

  const liffLogin = (PageTransitionType?: PageTransitionType) => {
    pageTransition({ url: `https://liff.line.me/${liffId}${callbackPath}`, type: PageTransitionType });
  };

  const browserLogin = () => {
    const currentOrigin = window.location.origin;
    liff.login({ redirectUri: `${currentOrigin}${callbackPath}` });
  };

  const lineUrlSchemeLogin = () => {
    const os = liff.getOS();
    const url =
      os === 'android'
        ? `intent://liff.line.me/${liffId}${callbackPath}#Intent;scheme=https;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=jp.naver.line.android;end`
        : os === 'ios'
          ? `line://app/${liffId}${callbackPath}`
          : `https://liff.line.me/${liffId}${callbackPath}`;
    pageTransition({ url, type: 'push' });
  };

  const lineAddFriend = (PageTransitionType?: PageTransitionType) => {
    const url = lineFriendUrl(environment);
    pageTransition({ url, type: PageTransitionType });
  };

  const normalLogin = (PageTransitionType?: PageTransitionType) => {
    if (liff.getOS() === 'web') {
      browserLogin();
      return;
    }

    liffLogin(PageTransitionType);
  };

  return { liffLogin, browserLogin, lineUrlSchemeLogin, lineAddFriend, normalLogin };
};
