import { useMemo } from 'react';
import { queryParamStringify } from '~/utils/url-param';
import { useTrackingManager } from './useTrackingManager';

type Props = {
  redirectPath: string;
  loginSourcePath?: string;
  action?: string;
};
export const useLoginQueryParameter = (props: Props) => {
  const { redirectPath, loginSourcePath, action } = props;
  const customUserId = useMemo(() => {
    try {
      return localStorage.getItem('custom_user_id');
    } catch (e) {
      return null;
    }
  }, []);
  const gclid = useMemo(() => {
    try {
      return localStorage.getItem('gclid');
    } catch (e) {
      return null;
    }
  }, []);
  const { tracking } = useTrackingManager();
  const loginQueryParameter = useMemo(() => {
    return queryParamStringify({
      params: { ...tracking, redirectPath, loginSourcePath, action, customUserId, gclid }
    });
  }, [action, customUserId, gclid, loginSourcePath, redirectPath, tracking]);

  return { loginQueryParameter };
};
