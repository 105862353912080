import { type FunctionComponent } from 'react';
import styles from '~/styles/parts/loading.module.css';

const blockClass = 'bars';

type Props = {
  isFull?: boolean;
};

export const Loading: FunctionComponent<Props> = ({ isFull }) => {
  return (
    <div className={`${styles.loading} ${isFull ? styles['is-full'] : ''}`}>
      <div className={styles[blockClass]}>
        {[...Array(6)].map((_, i) =>
        <span key={i} className={[styles[`${blockClass}__bar`], styles[`${blockClass}__bar--${i}`]].join(' ')} />
        )}
        {[...Array(6)].map((_, i) =>
        <span
          key={i}
          className={[
          styles[`${blockClass}__bar`],
          styles[`${blockClass}__bar--alt`],
          styles[`${blockClass}__bar--${i}--alt`]].
          join(' ')} />

        )}
      </div>
    </div>);

};