import { useClientEnvContext } from '~/providers';

type AnalyticsHook = {
  initializeUser: (userId: string) => void;
  setCustomeUserId: () => void;
  sendPageEvent: () => void;
  sendTrackEvent: (eventName: string, properties?: Record<string, any>) => void;
};

export const useAnalytics = (): AnalyticsHook => {
  const { gaTrackingId } = useClientEnvContext();

  const initializeUser = (userId: string) => {
    if (!(window as any).gtag) return;
    if (!gaTrackingId) return;

    (window as any).gtag('set', 'user_id', userId);
  };

  const setCustomeUserId = () => {
    const customUserId = localStorage.getItem('custom_user_id');
    if (customUserId) return;

    const generatedCustomUserId = crypto.randomUUID();
    localStorage.setItem('custom_user_id', generatedCustomUserId);
  };

  const sendPageEvent = () => {
    if (!(window as any).gtag) return;
    if (!gaTrackingId) return;

    (window as any).gtag('config', gaTrackingId, {
      page_title: window.location.pathname,
      page_location: window.location.href
    });

    sendTrackEvent('manual_page_view');
  };

  const sendTrackEvent = (eventName: string, properties?: Record<string, any>) => {
    if (!(window as any).gtag) return;

    (window as any).gtag('event', eventName, {
      ...properties,
      event_category: 'All',
      event_label: 'event',
      page_title: window.location.pathname,
      page_location: window.location.href,
      url: window.location.href,
      page_path: window.location.pathname,
      custom_user_id: localStorage.getItem('custom_user_id')
    });
  };

  return { initializeUser, setCustomeUserId, sendPageEvent, sendTrackEvent };
};
