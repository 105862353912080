import { createContext, useContext, type FunctionComponent, type ReactNode } from 'react';
import { type EnvironmentalVariables } from '~/types/environmentVariables';

type ContextType = EnvironmentalVariables;

export const ClientEnvContext = createContext<ContextType>({
  graphqlBaseUrl: '',
  liffId: '',
  baseUrl: '',
  environment: 'development',
  gaTrackingId: '',
  sentryDsn: '',
  lineAccountId: '',
  miniappLiffId: '',
  gtmId: ''
});
export const useClientEnvContext = (): ContextType => useContext(ClientEnvContext);

type Props = EnvironmentalVariables & {
  children: ReactNode;
};

export const ClientEnvProvider: FunctionComponent<Props> = (props) => {
  const { children, ...environmentalVariables } = props;

  return <ClientEnvContext.Provider value={environmentalVariables}>{children}</ClientEnvContext.Provider>;
};
