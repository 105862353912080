import type { StringifyOptions } from 'query-string';
import queryString from 'query-string';

type QueryParamStringifyProps = {
  params: Record<string, unknown>;
  includeQuestion?: boolean;
  stringifyOptions?: Exclude<StringifyOptions, 'skipEmptyString' | 'skipNull'>;
};
export const queryParamStringify = ({
  params,
  includeQuestion = false,
  stringifyOptions
}: QueryParamStringifyProps) => {
  const strParams = queryString.stringify(params, {
    skipEmptyString: true,
    skipNull: true,
    ...stringifyOptions
  });
  if (!includeQuestion) {
    return strParams;
  }
  return strParams ? `?${strParams}` : '';
};
