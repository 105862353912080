import { type FunctionComponent } from 'react';
import { useAnalytics } from '~/hooks';
import { Link, type LinkProps } from '@remix-run/react';
import { type AnalyticsData } from '~/types/analytics';

interface Props extends LinkProps {
  isExternal?: boolean;
  onClickEvent?: (e?: React.MouseEvent) => void;
  analyticsData?: AnalyticsData;
}

export const TrackableLink: FunctionComponent<Props> = (linkProps) => {
  const { sendTrackEvent } = useAnalytics();
  const { className, children, onClickEvent, analyticsData, isExternal, to, ...props } = linkProps;
  const handleClickLink = (e: React.MouseEvent) => {
    analyticsData && sendTrackEvent(analyticsData.eventName, analyticsData.properties);
    onClickEvent && onClickEvent(e);
  };

  if (isExternal || false) {
    return (
      <a href={to as string} className={className} onClick={handleClickLink} {...props}>
        {children}
      </a>
    );
  }

  return (
    <Link className={className} onClick={handleClickLink} to={to} {...props}>
      {children}
    </Link>
  );
};
