import { useRef, useEffect } from 'react';
import { useShallow } from 'zustand/shallow';
import { useTrackingManageStore } from '~/store/tracking-store';

export const useTrackingManager = () => {
  const { setTracking, tracking } = useTrackingManageStore(
    useShallow((state) => ({
      setTracking: state.setTracking,
      tracking: state.tracking
    }))
  );
  const trackingRef = useRef(tracking);

  useEffect(() => {
    trackingRef.current = tracking;
  }, [tracking]);

  return {
    setTracking,
    trackingRef,
    tracking
  };
};
